// 封装一些工具方法
// import axios from 'axios'
// 函数防抖
export function debounce (fn, delay) {
  let timer
  return function (...args) {
    clearTimeout(timer) // 清除上一次开的定时器
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

// 函数节流
export function throttle (fn, delay) {
  let valid = true
  return function (...args) {
    if (!valid) {
      // 休息时间 暂不接客
      return false
    }
    // 工作时间，执行函数并且在间隔期内把状态位设为无效
    valid = false
    setTimeout(() => {
      fn.apply(this, args)
      valid = true
    }, delay)
  }
}
// 打开万声音乐App
export function openApp (type, Id, url) {
  var iosLinkUrl = '' // ios端无链接
  var androidLinkurl
  if (type === 0) {
    //  androidLinkurl = 'https://hls.allsaintsmusic.com/m/0987a78636b44484804393abb7468c22/000_00000.m3u8'
    androidLinkurl = 'allmusic://preparePlay/targetId/' + Id + '/targetType/0/type/' + type + ''
  } else if (type === 1) {
    androidLinkurl = 'allmusic://songlist/detail/id/' + Id + '/type/' + type + ''
  } else if (type === 2) {
    androidLinkurl = 'allmusic://album/detail/albumId/' + Id + ''
  } else { // 播放协议
    //  androidLinkurl = url
  }
  var u = navigator.userAgent
  var isAndroid
  let isIOS // eslint-disable-line no-unused-vars
  init()

  function init () {
    isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // android终端
    isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
    var link = isAndroid ? androidLinkurl : iosLinkUrl
    location.href = link
  }
}

// 返回app首页
export function handleBack () {
  var androidLinkurl = 'allmusic://main/home'
  var u = navigator.userAgent
  var isAndroid
  isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
  if (isAndroid) {
    location.href = androidLinkurl
  } else {
    this.$router.replace({
      path: '/home'
    })
  }
}
export function dateFormat (dateStr, pattern = '') {
  if (!dateStr) {
    return ''
  }
  var dt = new Date(parseInt(dateStr * 1000))
  var y = dt.getFullYear()
  var m = (dt.getMonth() + 1).toString().padStart(2, 0)
  var d = dt
    .getDate()
    .toString()
    .padStart(2, 0)
  if (pattern.toLowerCase() === 'yyyy-mm-dd') {
    return `${y}年${m}月${d}日`
  } else {
    // var hh = dt
    //   .getHours()
    //   .toString()
    //   .padStart(2, 0);
    // var mm = dt
    //   .getMinutes()
    //   .toString()
    //   .padStart(2, 0);

    // var ss = dt
    //   .getSeconds()
    //   .toString()
    //   .padStart(2, 0);

    // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    return `${y}年${m}月${d}日`
  }
}
// 获取本地上传的图片文件的url txt文件路径也可以得到
export function getObjectURL (file) {
  var url = null
  if (window.createObjectURL !== undefined) {
    url = window.createObjectURL(file)
  } else if (window.URL !== undefined) {
    url = window.URL.createObjectURL(file)
  } else if (window.webkitURL !== undefined) {
    url = window.webkitURL.createObjectURL(file)
  }
  return url
}
