<template>
  <div class="home">
    <div class="music-banner" v-if="musicDataArr">
      <audio ref="audio" src="" />
      <div class="scine-zero" v-if="musicDataArr.scinezero">
        <img class="" src="@/assets/music-images/scineaZero_0.gif" v-lazy="musicDataArr.scinezero[0].img" alt="">
        <img class="" @click="audioScineaZero()" src="@/assets/music-images/scineaZero_1.gif"
          v-lazy="musicDataArr.scinezero[1].img" alt="">
        <img class="" src="@/assets/music-images/scineaZero_2.gif" v-lazy="musicDataArr.scinezero[2].img" alt="">
      </div>
      <div class="scine-one" v-if="musicDataArr.scineone">
        <img class="" src="@/assets/music-images/scineaOne_0.gif" v-lazy="musicDataArr.scineone[0].img" alt="">
        <img class="" src="@/assets/music-images/scineaOne_1.gif" v-lazy="musicDataArr.scineone[1].img" alt="">
        <img class="" src="@/assets/music-images/scineaOne_2.gif" @click="audioScineaOneFrist"
          v-lazy="musicDataArr.scineone[2].img" alt="">
        <img class="" src="@/assets/music-images/scineaOne_3.gif" @click="audioScineaOneSec"
          v-lazy="musicDataArr.scineone[3].img" alt="">
        <img class="" src="@/assets/music-images/scineaOne_4.gif" @click="audioScineaOneThree"
          v-lazy="musicDataArr.scineone[4].img" alt="">
      </div>
      <div class="scine-two" v-if="musicDataArr.scinetwo">
        <img class="scineaTwo_0" src="@/assets/music-images/scineaTwo_0.jpg" v-lazy="musicDataArr.scinetwo[0].img"
          alt="">
        <img class="scineaTwo_1" @click="audioScineTwoFrist" ref="refScineTwo"
          src="@/assets/music-images/scineaTwo_1.jpg" v-lazy="musicDataArr.scinetwo[1].img" alt="">
        <img class="scineaTwo_2" src="@/assets/music-images/scineaTwo_2.jpg" v-lazy="musicDataArr.scinetwo[2].img"
          alt="">
        <img class="scineaTwo_3" @click="audioScineTwoSec" src="@/assets/music-images/scineaTwo_3.gif"
          v-lazy="musicDataArr.scinetwo[3].img" alt="">
        <img class="scineaTwo_4" @click="audioScineTwoFour" src="@/assets/music-images/scineaTwo_4.gif"
          v-lazy="musicDataArr.scinetwo[4].img" alt="">
      </div>
      <div class="scine-three" v-if="musicDataArr.scinethree">
        <img class="" src="@/assets/music-images/scineaThree_0.gif" v-lazy="musicDataArr.scinethree[0].img" alt="">
        <img class="" src="@/assets/music-images/scineaThree_1.gif" v-lazy="musicDataArr.scinethree[1].img" alt="">
        <img class="" @click="audioScineThreeFrist" src="@/assets/music-images/scineaThree_2.gif"
          v-lazy="musicDataArr.scinethree[2].img" alt="">
        <img class="" src="@/assets/music-images/scineaThree_3.gif" v-lazy="musicDataArr.scinethree[3].img" alt="">
        <img class="" src="@/assets/music-images/scineaThree_4.gif" v-lazy="musicDataArr.scinethree[4].img" alt="">
      </div>
      <div class="scine-four" v-if="musicDataArr.scinefour">
        <img class="" src="@/assets/music-images/scineaFour_0.gif" v-lazy="musicDataArr.scinefour[0].img" alt="">
        <img class="" @click="audioScineaFourFrist" src="@/assets/music-images/scineaFour_1.gif"
          v-lazy="musicDataArr.scinefour[1].img" alt="">
        <img class="" @click="audioScineaFourSec" src="@/assets/music-images/scineaFour_2.gif"
          v-lazy="musicDataArr.scinefour[2].img" alt="">
        <img class="" @click="audioScineaFourThree" src="@/assets/music-images/scineaFour_3.gif"
          v-lazy="musicDataArr.scinefour[3].img" alt="">
        <img class="" src="@/assets/music-images/scineaFour_4.gif" v-lazy="musicDataArr.scinefour[4].img" alt="">
        <img class="" src="@/assets/music-images/scineaFour_5.gif" alt="">
      </div>
      <div class="scine-five" v-if="musicDataArr.scinefive">
        <img class="" src="@/assets/music-images/scineaFive_0.gif" v-lazy="musicDataArr.scinefive[0].img" alt="">
        <img class="" @click="audioScineaFiveFrist" src="@/assets/music-images/scineaFive_1.gif"
          v-lazy="musicDataArr.scinefive[1].img" alt="">
        <img class="" src="@/assets/music-images/scineaFive_2.gif" v-lazy="musicDataArr.scinefive[2].img" alt="">
        <img class="" src="@/assets/music-images/scineaFive_3.gif" @click="audioScineaFiveThree"
          v-lazy="musicDataArr.scinefive[3].img" alt="">
        <img class="" src="@/assets/music-images/scineaFive_4.gif" v-lazy="musicDataArr.scinefive[4].img" alt="">
        <img class="" @click="audioScineaFiveFive" src="@/assets/music-images/scineaFive_5.gif"
          v-lazy="musicDataArr.scinefive[5].img" alt="">
        <img class="" src="@/assets/music-images/scineaSix_9.gif" alt="">
        <img class="" src="@/assets/music-images/scineaFive_7.gif" v-lazy="musicDataArr.scinefive[7].img" alt="">
      </div>
      <div class="scine-six" v-if="musicDataArr.scinesix">
        <img class="" src="@/assets/music-images/scineaSix_0.gif" v-lazy="musicDataArr.scinesix[0].img" alt="">
        <img class="" @click="audioScineaSixFrist" src="@/assets/music-images/scineaSix_1.gif"
          v-lazy="musicDataArr.scinesix[1].img" alt="">
        <img class="" src="@/assets/music-images/scineaSix_2.gif" v-lazy="musicDataArr.scinesix[2].img" alt="">
        <img class="" @click="audioScineaSixThree" src="@/assets/music-images/scineaSix_3.gif"
          v-lazy="musicDataArr.scinesix[3].img" alt="">
        <img class="" src="@/assets/music-images/scineaSix_4.gif" v-lazy="musicDataArr.scinesix[4].img" alt="">
        <img class="" @click="audioScineaSixFive" src="@/assets/music-images/scineaSix_5.gif"
          v-lazy="musicDataArr.scinesix[5].img" alt="">
        <!-- <img class="" src="../assets/music-images/scineaSix_6.gif" alt=""> -->
        <img class="" src="@/assets/music-images/scineaSix_7.gif" v-lazy="musicDataArr.scinesix[6].img" alt="">
        <!-- <img class="" src="../assets/music-images/scineaSix_8.gif" alt=""> -->
        <img class="" src="@/assets/music-images/scineaSix_9.gif" alt="">
        <img class="" src="@/assets/music-images/scineaSix_10.gif" alt="">
        <img class="" src="@/assets/music-images/scineaSix_11.gif" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import {
    openApp
  } from '@/utils'
  // import { Toast } from 'vant'

  export default {
    name: 'Home',
    data() {
      return {
        musicDataArr: {},
        scineaZeroUrl: '',
        images: [
          'https://img01.yzcdn.cn/vant/apple-1.jpg',
          'https://img01.yzcdn.cn/vant/apple-2.jpg',
          'https://img01.yzcdn.cn/vant/apple-3.jpg',
          'https://img01.yzcdn.cn/vant/apple-4.jpg',
          'https://img01.yzcdn.cn/vant/apple-5.jpg'
        ],
        clicktime: 0,
        clicktime2: 0,
        clicktimetotal: 0,
        currentAudio: {},
        playStatus0: false,
        playStatus1: false,
        playStatus2: false,
        playStatus3: false,
        playStatus4: false,
        playStatus5: false,
        playStatus6: false,
        playStatus7: false,
        playStatus8: false,
        playStatus9: false,
        playStatus10: false,
        playStatus11: false,
        playStatus12: false,
        playStatus13: false,
        playStatus14: false,
        playStatus15: false,
        playStatus16: false
      }
    },

    components: {
      // HelloWorld
    },
    created() {
      this.axiosFuc()
    },
    mounted() {
      // 登录01
      // this.$store.dispatch('user/login')
    },
    methods: {
      // 发axios请求
      axiosFuc() {
        this.$axios.post('music', {})
          .then((res) => {
            this.musicDataArr = res.data.data
          })
          .catch((error) => {
            // Toast('提示内容')
            console.log(error)
          })
      },
      // 点击播放
      audioScineaZero() {
        if (this.musicDataArr.scinezero[1].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinezero[1].url)
          this.playStatus0 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus0 = !this.playStatus0
        } else {
          openApp(this.musicDataArr.scinezero[1].type, this.musicDataArr.scinezero[1].id)
        }
      },
      audioScineaOneFrist() {
        if (this.musicDataArr.scineone[2]
          .url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scineone[2].url)
          this.playStatus1 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus1 = !this.playStatus1
        } else {
          openApp(this.musicDataArr.scineone[2].type, this.musicDataArr.scineone[2].id)
        }
      },
      audioScineaOneSec() {
        if (this.musicDataArr.scineone[3].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scineone[3].url)
          this.playStatus2 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus2 = !this.playStatus2
        } else {
          openApp(this.musicDataArr.scineone[3].type, this.musicDataArr.scineone[3].id)
        }
      },
      audioScineaOneThree() {
        if (this.musicDataArr.scineone[4].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scineone[4].url)
          this.playStatus3 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus3 = !this.playStatus3
        } else {
          openApp(this.musicDataArr.scineone[4].type, this.musicDataArr.scineone[4].id)
        }
      },
      audioScineTwoFrist() {
        if (this.musicDataArr.scinetwo[1].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinetwo[1].url)
          this.playStatus4 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus4 = !this.playStatus4
        } else {
          openApp(this.musicDataArr.scinetwo[1].type, this.musicDataArr.scinetwo[1].id)
        }
      },
      audioScineTwoSec() {
        if (this.musicDataArr.scinetwo[3].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinetwo[3].url)
          this.playStatus5 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus5 = !this.playStatus5
        } else {
          openApp(this.musicDataArr.scinetwo[3].type, this.musicDataArr.scinetwo[3].id)
        }
      },
      audioScineTwoFour() {
        if (this.musicDataArr.scinetwo[4].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinetwo[4].url)
          this.playStatus6 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus6 = !this.playStatus6
        } else {
          openApp(this.musicDataArr.scinetwo[4].type, this.musicDataArr.scinetwo[4].id)
        }
      },
      audioScineThreeFrist() {
        if (this.musicDataArr.scinethree[2].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinethree[2].url)
          this.playStatus7 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus7 = !this.playStatus7
        } else {
          openApp(this.musicDataArr.scinethree[2].type, this.musicDataArr.scinethree[2].id)
        }
      },
      audioScineaFourFrist() {
        if (this.musicDataArr.scinefour[1]
          .url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinefour[1].url)
          this.playStatus8 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus8 = !this.playStatus8
        } else {
          openApp(this.musicDataArr.scinefour[1].type, this.musicDataArr.scinefour[1].id)
        }
      },
      audioScineaFourSec() {
        if (this.musicDataArr.scinefour[2]
          .url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinefour[2].url)
          this.playStatus9 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus9 = !this.playStatus9
        } else {
          openApp(this.musicDataArr.scinefour[2].type, this.musicDataArr.scinefour[2].id)
        }
      },
      audioScineaFourThree() {
        if (this.musicDataArr.scinefour[3]
          .url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinefour[3].url)
          this.playStatus10 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus10 = !this.playStatus10
        } else {
          openApp(this.musicDataArr.scinefour[3].type, this.musicDataArr.scinefour[3].id)
        }
      },
      audioScineaFiveFrist() {
        if (this.musicDataArr.scinefive[1]
          .url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinefive[1].url)
          this.playStatus11 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus11 = !this.playStatus11
        } else {
          openApp(this.musicDataArr.scinefive[1].type, this.musicDataArr.scinefive[1].id)
        }
      },
      audioScineaFiveThree() {
        if (this.musicDataArr.scinefive[3]
          .url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinefive[3].url)
          this.playStatus12 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus12 = !this.playStatus12
        } else {
          openApp(this.musicDataArr.scinefive[3].type, this.musicDataArr.scinefive[3].id)
        }
      },

      audioScineaFiveFive() {
        if (this.musicDataArr.scinefive[5]
          .url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinefive[5].url)
          this.playStatus13 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus13 = !this.playStatus13
        } else {
          openApp(this.musicDataArr.scinefive[5].type, this.musicDataArr.scinefive[5].id)
        }
      },
      audioScineaSixFrist() {
        if (this.musicDataArr.scinesix[1].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinesix[1].url)
          this.playStatus14 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus14 = !this.playStatus14
        } else {
          openApp(this.musicDataArr.scinesix[1].type, this.musicDataArr.scinesix[1].id)
        }
      },
      audioScineaSixThree() {
        if (this.musicDataArr.scinesix[3].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinesix[3].url)
          this.playStatus15 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus15 = !this.playStatus15
        } else {
          openApp(this.musicDataArr.scinesix[3].type, this.musicDataArr.scinesix[3].id)
        }
      },
      audioScineaSixFive() {
        if (this.musicDataArr.scinesix[5].url) {
          this.$refs.audio.setAttribute('src', this.musicDataArr.scinesix[5].url)
          this.playStatus16 ? this.$refs.audio.pause() : this.$refs.audio.play()
          this.playStatus16 = !this.playStatus16
        } else {
          openApp(this.musicDataArr.scinesix[5].type, this.musicDataArr.scinesix[5].id)
        }
      }

    }
  }

</script>

<style lang="less" scoped>
  .music-banner {
    width: 100%;
    height: 100%;

    .scine-zero {
      width: 100%;
      text-align: center;
      // font-size: 0;

      img {
        display: block;
        width: 100%;
      }

      .video {
        padding-top: 20px;
      }
    }

    .scine-one {
      width: 100%;
      font-size: 0;

      img {
        width: 100%;
      }
    }

    .scine-two {
      width: 100%;

      img {
        width: 100%;
        vertical-align: top;
      }

      .scineaTwo_0 {
        margin-bottom: -1px;
      }

      .scineaTwo_1 {
        width: 38%;
      }

      .scineaTwo_2 {
        width: 62%;
      }

      .scineaTwo_3,
      .scineaTwo_4 {
        width: 100%;
      }
    }

    .scine-three {
      font-size: 0;

      img {
        width: 100%;
      }
    }

    .scine-four {
      font-size: 0;

      img {
        width: 100%;
      }
    }

    .scine-five {
      font-size: 0;

      img {
        width: 100%;
      }
    }

    .scine-six {
      width: 100%;
      font-size: 0;

      img {
        width: 100%;
      }

      .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: #39a9ed;
      }
    }

    .open-app {
      position: fixed;
      bottom: 2.6rem;
      left: 50%;
      width: 16.4rem;
      height: 3.13rem;
      margin-left: -8.2rem;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 1.56rem;

      .app-title {
        margin: 0 auto;

        .app-logo {
          width: 1.56rem;
          height: 1.56rem;
          margin-right: 0.52rem;
          vertical-align: middle;
        }

        .oppo {
          color: #fff;
          font-size: 0.91rem;
        }
      }
    }
  }

</style>
